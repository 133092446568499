import React from 'react';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import type { Session } from 'next-auth';
import Router from 'next/router';
import NProgress from 'nprogress';
import { SessionProvider } from 'next-auth/react';
import { SessionErrorHandler } from '../components/SessionErrorHandler';
import { Inter } from 'next/font/google'


// https://fontawesome.com/v5/docs/web/use-with/react#next-js
import { config } from '@fortawesome/fontawesome-svg-core';
import '../styles/globals.css';
import { App, ConfigProvider } from 'antd';
import EscapeAntd from '@/components/EscapeAntd';
config.autoAddCss = false;

type NextPageWithLayout = NextPage & {
    layout?: (page: React.ReactElement) => React.ReactNode;
}

type AppPropsWithLayout = AppProps<{
    session?: Session;
}> & {
    Component: NextPageWithLayout;
}

// Register Fonts Here

const inter = Inter({
    weight: ['100', '300', '500', '700'],
    subsets: ['latin'],
    variable: '--font-inter'
})

function CustomApp({
    Component,
    pageProps: { session, ...pageProps }
}: AppPropsWithLayout): JSX.Element {
    // https://nextjs.org/docs/basic-features/layouts#per-page-layouts
    const withLayout = Component.layout ?? (page => page);

    return (
        // https://next-auth.js.org/getting-started/client#sessionprovider
        <SessionProvider session={session}
            refetchInterval={120} refetchWhenOffline={false} refetchOnWindowFocus={false}>
            <SessionErrorHandler>
                <main className={`${inter.variable} font-sans`}>
                    <ConfigProvider
                        theme={{
                            components: {
                                Table: {
                                    fontFamily: inter.style.fontFamily,
                                    headerBg: '#A3A3A342',
                                    headerSortActiveBg: '#A3A3A398',
                                    colorPrimary: '#A3A3A398'
                                },
                                Select: {
                                    fontFamily: inter.style.fontFamily
                                },
                                Input: {
                                    fontFamily: inter.style.fontFamily
                                },
                                Tag: {
                                    fontFamily: inter.style.fontFamily
                                },
                                Button: {
                                    fontFamily: inter.style.fontFamily,
                                    defaultHoverColor: '#3788FD'
                                },
                                Collapse: {
                                    fontFamily: inter.style.fontFamily
                                },
                                Breadcrumb: {
                                    fontFamily: inter.style.fontFamily
                                },
                                Modal: {
                                    zIndexBase: 500
                                }
                            },
                        }}
                    >
                        <App>
                            <EscapeAntd />
                            {withLayout(<Component {...pageProps} />)}
                        </App>
                    </ConfigProvider>
                </main>
            </SessionErrorHandler>
        </SessionProvider>
    );
}

NProgress.configure({
    showSpinner: false
});

Router.events.on('routeChangeStart', NProgress.start);
Router.events.on('routeChangeComplete', NProgress.done);
Router.events.on('routeChangeError', NProgress.done);

export default CustomApp;
